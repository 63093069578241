module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://leguideparis1618.par-azar.com/graphql","schema":{"timeout":120000,"requestConcurrency":5,"previewRequestConcurrency":2,"perPage":20,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"includedRoutes":["**/categories","**/posts","**/pages","**/media","**/tags","**/taxonomies","**/users","**/projet"],"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Le Guide - Paris 1.618","short_name":"LeGuide","start_url":"/","background_color":"#000000","theme_color":"#0000ff","display":"minimal-ui","icon":"src/images/Favicon-64.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5bcb6433042cb904ba003c0a8382ee97"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-16919778-20","UA-91014539-1"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
